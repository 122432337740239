<script lang="ts" setup>
import type { TypedSchema } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useUser } from '@/stores/user'

const name = 'login-form'

const { t } = useI18n()
const userStore = useUser()

const emit = defineEmits(['resetPassword', 'goToSignup', 'success'])

const { requiredString } = useRules()
const validationSchema: ComputedRef<TypedSchema> = computed((): TypedSchema => {
  return toTypedSchema(
    zod.object({
      email: requiredString().email({
        message: t('base.form.emailNotValid'),
      }),
      password: requiredString(5, t('base.form.required')),
    })
  )
})

const { handleSubmit, errors } = useForm({
  validationSchema,
})

const { value: email } = useField<string>('email')
const { value: password } = useField<string>('password')

const toast = useToast()
const authRepository = createAuthRepository()
const unconfirmedEmail = ref(false)

const onSubmit = handleSubmit(async (values) => {
  try {
    const result = await authRepository.login({
      username: values.email,
      password: values.password,
    })
    userStore.setUser(result)
    emit('success')
  } catch (error) {
    // TODO: How do we want to handle this?
    if (error.data.detail === 'NotAllowed') {
      // handle unconfirmed email
      unconfirmedEmail.value = true
    } else {
      toast.add({
        title: t('base.login.error'),
        color: 'red',
      })
    }
  }
})

function resetPassword(): void {
  emit('resetPassword', false)
}

function goToSignupForm(): void {
  emit('goToSignup')
}

async function resendConfirmationMail() {
  try {
    await authRepository.resendConfirmationEmail({
      email: email.value,
    })
    toast.add({
      title: t('base.login.confirmationEmail.sent'),
      color: 'green',
    })
  } catch {
    toast.add({
      title: t('base.login.confirmationEmail.error'),
      color: 'red',
    })
  } finally {
    unconfirmedEmail.value = false
  }
}
</script>

<template>
  <div>
    <UModal
      v-model="unconfirmedEmail"
      prevent-close
      :ui="{ width: 'w-full sm:max-w-screen-sm', rounded: 'rounded-none' }"
    >
      <div>
        <div class="mx-auto bg-white p-10" @click.stop>
          <div class="whitespace-normal pb-4 text-lg font-semibold">
            {{ $t('base.login.unconfirmedEmail') }}
          </div>
          <NuxtLink
            class="cursor-pointer pb-4 text-lg font-semibold text-black"
            @click.prevent="resendConfirmationMail()"
          >
            {{ $t('base.login.confirmationEmail.resend') }}
          </NuxtLink>
          <div class="px-8 pt-8">
            <button
              class="w-full border-2 border-black px-8 py-4 text-xl font-bold"
              @click="unconfirmedEmail = false"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </UModal>
    <form @submit.prevent="onSubmit">
      <div>
        <FormFieldInput
          v-model="email"
          name="email"
          :parent-name="name"
          property-name="login-email"
          label="signup.email"
          :icon="'envelope'"
          required
          :error="errors.email"
          autocomplete="username"
        />
        <FormFieldInput
          v-model="password"
          name="password"
          :parent-name="name"
          property-name="login-password"
          type="password"
          label="signup.password"
          :icon="'lock'"
          :error="errors.password"
          required
          autocomplete="current-password"
        />

        <div class="flex flex-wrap">
          <div class="flex basis-1/2 px-0">
            <div class="text-left">
              <p class="my-4">
                <a
                  class="cursor-pointer text-xs font-semibold text-black underline"
                  @click.prevent="goToSignupForm()"
                >
                  {{ $t('base.login.signup') }}
                </a>
              </p>
            </div>
          </div>
          <div class="flex basis-1/2 px-0">
            <div class="w-full text-right">
              <p class="my-4">
                <a
                  class="cursor-pointer text-xs font-semibold text-black underline"
                  @click.prevent="resetPassword()"
                >
                  {{ $t('base.login.forgotPassword') }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        class="w-full border-2 border-black px-8 py-4 text-xl font-bold"
        type="submit"
      >
        {{ $t('base.login.submit') }}
      </button>
    </form>
  </div>
</template>

<style></style>
