<script lang="ts" setup>
import type { TypedSchema } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    status: boolean | null
  }>(),
  {
    status: null,
  }
)

const emit = defineEmits(['update:status', 'backToLogin', 'goToSignup'])

const localStatus = ref<boolean | null>(null)

watch(
  () => props.status,
  (val) => {
    localStatus.value = val
  }
)
watch(localStatus, (val) => {
  emit('update:status', val)
})

const { requiredString } = useRules()
const validationSchema: ComputedRef<TypedSchema> = computed((): TypedSchema => {
  return toTypedSchema(
    zod.object({
      email: requiredString().email({
        message: t('base.form.emailNotValid'),
      }),
    })
  )
})

const { handleSubmit, errors } = useForm({
  validationSchema,
})

const { value: email } = useField<string>('email')

const toast = useToast()

const onSubmit = handleSubmit(async (values) => {
  try {
    await createAuthRepository().forgotPassword({
      email: values.email,
    })
    localStatus.value = true
  } catch (error) {
    // TODO: How do we want to handle this?
    toast.add({
      title: t('base.login.error'),
      color: 'red',
    })
    localStatus.value = false
  }
})

function backToLogin(): void {
  emit('backToLogin', false)
}

function goToSignupForm(): void {
  emit('goToSignup')
}
</script>

<template>
  <div>
    <p class="-mt-8 mb-12 whitespace-normal text-lg">
      {{ $t('base.login.passwordReset.description') }}
    </p>
    <form @submit.prevent="onSubmit">
      <div>
        <FormFieldInput
          v-model="email"
          name="email"
          property-name="password-reset-email"
          label="signup.email"
          :icon="'envelope'"
          required
          :error="errors.email"
          autocomplete="username"
        />

        <div class="flex flex-wrap">
          <div class="flex basis-1/2 px-0">
            <div class="text-left">
              <p class="my-4">
                <a
                  class="cursor-pointer text-xs font-semibold text-black underline"
                  @click.prevent="backToLogin()"
                >
                  {{ $t('base.login.passwordReset.backToLogin') }}
                </a>
              </p>
            </div>
          </div>
          <div class="flex basis-1/2 px-0">
            <div class="w-full text-right">
              <p class="my-4">
                <a
                  class="cursor-pointer text-xs font-semibold text-black underline"
                  @click.prevent="goToSignupForm()"
                >
                  {{ $t('base.login.signup') }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        class="w-full border-2 border-black px-8 py-4 text-xl font-bold"
        type="submit"
      >
        {{ $t('base.login.passwordReset.submit') }}
      </button>
    </form>
  </div>
</template>

<style></style>
