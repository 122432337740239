<script lang="ts" setup>
const { alert } = useAlert()

const { availableLocales, locale, t } = useI18n()

const props = withDefaults(
  defineProps<{
    inPopup?: boolean
  }>(),
  {
    inPopup: false,
  }
)

const emit = defineEmits(['closePopup', 'update:titlePath'])

const status = ref<null | boolean>(null)
const showPasswordReset = ref(false)

const titlePath = computed(() => {
  if (showPasswordReset.value) {
    return t('base.login.passwordReset.title')
  }
  return t('base.login.title')
})

watch(status, (val) => {
  if (!props.inPopup) {
    if (val === true) {
      alert(['base.login.passwordReset.success'])
    } else if (val === false) {
      alert(['base.login.passwordReset.error'])
    }
  }
})

watch(
  showPasswordReset,
  (val) => {
    if (!val) {
      status.value = null
    }
    emit('update:titlePath', titlePath.value)
  },
  { immediate: true }
)

function goToSignupForm(): void {
  const fragment = 'registration'
  const rootPathNames = availableLocales.map((loc) => `/${loc}`)
  if (rootPathNames.includes(location.pathname)) {
    showPasswordReset.value = false
    emit('closePopup')
    setTimeout(() => {
      document.getElementById(fragment)?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  } else {
    window.location.href = `/${locale.value}#${fragment}`
  }
}

function resetPassword(): void {
  showPasswordReset.value = true
}

function handleBackToLogin(): void {
  showPasswordReset.value = false
}
</script>

<template>
  <template v-if="!showPasswordReset">
    <h3 v-if="!inPopup" class="my-6 text-2xl font-bold">
      {{ titlePath }}
    </h3>
    <LoginForm
      @success="emit('closePopup')"
      @go-to-signup="goToSignupForm()"
      @reset-password="resetPassword()"
    />
  </template>
  <template v-else>
    <h3 v-if="!inPopup" class="py-6 text-2xl font-bold">
      {{ $t(titlePath) }}
    </h3>
    <LoginPasswordResetForm
      v-if="status === null"
      :status="status"
      @update:status="(val) => (status = val)"
      @go-to-signup="goToSignupForm()"
      @back-to-login="handleBackToLogin()"
    />
    <p v-else class="whitespace-normal pb-4 text-lg font-semibold">
      {{
        status === true
          ? $t('base.login.passwordReset.success')
          : $t('base.login.passwordReset.error')
      }}
    </p>
  </template>
</template>

<style></style>
